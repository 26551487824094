<template>
    <v-card class="card-action-container">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <v-icon>lightbulb_outline</v-icon>
            <span>Bulk</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <CustomToolbarBtn
              :is-selected="isAction"
              tab="action"
              tab-label="Action"
              @changeTab="changeTab"
            >
            </CustomToolbarBtn>
            <CustomToolbarBtn
              :is-selected="isOverwrite"
              tab="overwrite"
              tab-label="Overwrite"
              @changeTab="changeTab"
            >
            </CustomToolbarBtn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title class="bg-title-action">
        <span class="headline">{{title}}</span>
        </v-card-title>

        <v-card-text>
        <v-container grid-list-md class="action-form-container">
            <div v-if="isAction">
              <div class="text-center bold-weight">These actions will affect <span class="number-of-selected">{{calcItemSelected}}</span> ids : </div>
              <v-col class="py-2 xs12 sm2" v-if="$route.params.dsp !== $YOUTUBE">
                <p> Retry </p>
                <v-btn-toggle v-model="action.retry" mandatory>
                  <v-btn text>
                    false
                  </v-btn>
                  <v-btn text>
                    N/A
                  </v-btn>
                  <v-btn text>
                    true
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col class="py-2 xs12 sm2">
                <p> Fast retry </p>
                <v-btn-toggle v-model="action.fast_retry" mandatory>
                  <v-btn text>
                    false
                  </v-btn>
                  <v-btn text>
                    N/A
                  </v-btn>
                  <v-btn text>
                    true
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col class="py-2 xs12 sm2">
                <p>Reactivate otto afterwards</p>
                <v-btn-toggle v-model="action.reactivate_otto" mandatory>
                  <v-btn text>
                    false
                  </v-btn>
                  <v-btn text>
                    N/A
                  </v-btn>
                  <v-btn text>
                    true
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col class="py-2 xs12 sm2" v-if="allowUpdateActive">
                <p> Active </p>
                <v-btn-toggle v-model="action.is_active" mandatory>
                  <v-btn text>
                    false
                  </v-btn>
                  <v-btn text>
                    N/A
                  </v-btn>
                  <v-btn text>
                    true
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <!-- <v-switch v-model="action.opti_auto" :label="`Opti auto: ${action.opti_auto.toString()}`"></v-switch> -->
            </div>
            <div v-if="isOverwrite">
              <BulkOverwrite
                v-model="overwrites"
                :io-list="ioList"
                :simulationErrors="bulkValidationError"
              >
              </BulkOverwrite>
            </div>
        </v-container>
        </v-card-text>

        <v-card-actions class="bg-title-action">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="emitCloseAction">Cancel</v-btn>
        <v-btn color="blue darken-1" :loading="loadingSave" :disabled="isDisabledSave" text @click="clickOnSave">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import CustomToolbarBtn from '@/components/Common/CustomToolbarBtn.vue'
import BulkOverwrite from '@/components/TableComponents/Form/BulkAction/BulkOverwrite.vue'
import { capitalize } from 'lodash'

export default {
  name: 'ActionFormV2',
  components: { CustomToolbarBtn, BulkOverwrite },
  props: ['selected', 'action', 'numberOfSelectedItem', 'selectedIos'],
  data: function () {
    return {
      selectedLength: 0,
      allowUpdateActive: false,
      currentTab: 'action',
      /**
       * @type {OverwritesParam}
       */
      overwrites: {},
      loadingSave: false,
      loadingSimulate: false,
      bulkValidationError: {}
    }
  },
  methods: {
    emitCloseAction () {
      this.$emit('close-action')
    },
    emitActionMethod () {
      this.$emit('action-method')
    },
    changeTab (tab) {
      this.currentTab = tab
    },
    async clickOnSave () {
      if (this.isOverwrite) {
        await this.saveOverwrites()
      } else if (this.isAction) {
        this.emitActionMethod()
      } else {
        console.warn('Not implemented')
      }
    },
    async saveOverwrites () {
      this.loadingSave = true
      console.warn('Click on save overwrite')

      const objectLength = Object.keys(this.overwritesNotNull).length

      if (objectLength === 0) {
        let message = 'No overwrites to update'
        console.warn(message)
        this.$store.commit('setErrorMessage', message)
        this.loadingSave = false
        return
      }
      /**
       * @type {ParamsOverwritesBulkInstructions}
       */
      const params = {
        io_ids: this.ioList,
        overwrites: this.overwritesNotNull
      }

      const subDsp = this.$route.params.dsp
      const response = await this.$apiCaller.postOverwritesBulkInstructions(params, subDsp)
      if (this.$apiCaller.isResponseError(response)) {
        let message = 'Error when calling the api.'
        console.warn(message)
        this.$store.commit('setErrorMessage', message)
      } else {
        let message = 'Success'
        console.warn(message)
        this.$store.commit('setSuccessMessage', message)
        this.$emit('update-overwrites')
      }
      this.loadingSave = false
      this.emitCloseAction()
    },
    async validateOverwrite () {
      console.warn('Validate overwrite')
      this.loadingSimulate = true

      const objectLength = Object.keys(this.overwritesNotNull).length

      if (objectLength === 0) {
        let message = 'No overwrites to update'
        console.warn(message)
        this.loadingSimulate = false
        return
      }
      /**
       * @type {ParamsOverwritesBulkInstructions}
       */
      const params = {
        io_ids: this.ioList,
        overwrites: this.overwritesNotNull
      }

      const subDsp = this.$route.params.dsp
      const response = await this.$apiCaller.postSimulateOverwritesBulkInstructions(params, subDsp)
      if (this.$apiCaller.isResponseError(response)) {
        let message = 'Error when calling the api.'
        const status = response.response.status
        if (status === 400) {
          this.bulkValidationError = response.response.data.errors
        } else {
          this.$store.commit('setErrorMessage', message)
        }
      } else {
        let message = 'Instructions validated.'
        this.bulkValidationError = {}
        console.warn(message)
        // nothing
      }
      this.loadingSimulate = false
    }
  },
  computed: {
    calcItemSelected: function () {
      let selectedAtTrue = Object.keys(this.selected).filter(function (key, index) {
        return this.selected[key] === true
      }.bind(this))

      return selectedAtTrue.length
    },
    isAction: function () {
      return this.currentTab === 'action'
    },
    isOverwrite: function () {
      return this.currentTab === 'overwrite'
    },
    ioList: function () {
      return Object.keys(this.selectedIos).filter(function (key, index) {
        return this.selectedIos[key].state === true
      }.bind(this))
    },
    title: function () {
      return capitalize(this.currentTab)
    },
    overwritesNotNull () {
      const overwrites = _.cloneDeep(this.overwrites)
      for (let key in overwrites) {
        if (overwrites[key] === 'N/A') {
          delete overwrites[key]
        }
      }
      return overwrites
    },
    isDisabledSave: function () {
      const noItemSelected = this.calcItemSelected <= 0
      if (this.isOverwrite) {
        return noItemSelected || Object.keys(this.overwritesNotNull).length === 0
      }
      return noItemSelected
    }
  },
  watch: {
    overwrites: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.validateOverwrite()
      }
    }
  }
}
</script>

<style>
.number-of-selected {
  color: #3486D7
}

.action-form-container {
  height: 600px;
  overflow: auto;
}

.bg-title-action {
  background: #d3d3d361;
}

.card-action-container.v-card {
  padding: 0 !important;
}

.card-action-container {
  overflow: hidden;
}
</style>
